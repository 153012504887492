import React from 'react';

import { BlogCont } from '../styles/components/blog.styled'

import Seo from "../components/seo";
import Layout from "../components/layout";


const Blog = () => {
  return(
    <Layout>
      <Seo title="Blog"/>
      <BlogCont>
        Hola estes es un blog
      </BlogCont>
    </Layout>
  )
};

export default Blog;